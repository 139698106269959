<template>
  <el-card class="main-card">
    <template #header>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">进销存</el-breadcrumb-item>
        <el-breadcrumb-item v-if="type == 'in'">入库单详情</el-breadcrumb-item>
        <el-breadcrumb-item v-if="type == 'out'">出库单详情</el-breadcrumb-item>
      </el-breadcrumb>
    </template>

    <div class="toolbar">
      <el-button type="info" icon="Back" @click="back()">
        返回
      </el-button>
      <el-button type="primary" icon="printer" @click="onprint()">
        打印
      </el-button>
    </div>

    <section ref="print" id="print-panel">
      <div class="title-box">
        <h3 style=" font-size: 1.6em; font-weight: 500; text-align: center;  margin: 20px auto; margin-top: 30px;">
          {{ type == "in" ? "【入库】" : type == "out" ? "【出库】" : "" }}
          {{ title }}通知单 [{{ bill.billType }}]
        </h3>
        <el-row :gutter="10" style="padding: 12px">
          <el-col :xs="24" :sm="14" :md="16" :lg="16" :xl="16">
            <span style="margin-right: 20px">
              所属公司：
              <!-- <el-cascader v-model="bill.companyID" :options="companys" :props="props" style="max-width:150px"
                :disabled="true" /> -->
              <el-tree-select ref="companys" v-model="bill.companyID" :data="companys" @change="queryOrderEvent" disabled check-strictly
                :render-after-expand="false" style="width: 220px;" />
            </span>
            <span style=" margin-right: 20px"> 类型：{{ bill.billType }} </span>
            <span style=" margin-right: 20px"> 单号：{{ bill.billCode }} </span>
            <span style=" margin-right: 20px"> 库房：{{ bill.storeCode }} </span>
            <span style=" margin-right: 20px"> 渠道：{{ bill.channelCode }} </span>
          </el-col>

          <el-col :xs="24" :sm="10" :md="8" :lg="8" :xl="8" align="right">
            <span style="margin-right: 30px"> 关联单号：{{ bill.netCode || "无" }} </span>
            <span style="margin-right: 30px"> 时间：{{ bill.billDate }} </span>
          </el-col>
        </el-row>
      </div>
      <el-table class="table" :data="bounds" border style="width: 100%" highlight-current-row size="small"
        v-if="bounds.length > 0">
        <el-table-column label="序号" type="index" width="50" align="center"> </el-table-column>
        <el-table-column prop="channel" label="渠道" width="70" align="center"> </el-table-column>
        <el-table-column prop="storeCode" label="库房" width="70" align="center"> </el-table-column>
        <el-table-column prop="detailCode" label="系统款号" width="130" align="center"> </el-table-column>
        <el-table-column prop="brandModelCode" label="品牌款号" width="130" align="center" show-overflow-tooltip>
        </el-table-column>
        <!-- <el-table-column prop="detailCode" label="系统款号/品牌款号" width="150" align="center">
          <template #default="scope">
            <div>{{ scope.row.detailCode }}</div>
            <div>
              {{ (scope.row.brandModelCode || "") === ""? "" : "【" + scope.row.brandModelCode + "】" }}
            </div>
          </template>
        </el-table-column> -->
        <el-table-column prop="detailName" label="名称" sortable width="200" align="center" show-overflow-tooltip>
        </el-table-column>

        <el-table-column prop="brandCode" label="供应商" width="70" align="center" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="brandSerialCode" label="批次号" align="center" sortable width="120"> </el-table-column>

        <el-table-column prop="platformName" label="平台" width="65" align="center" v-if="type == 'out'"
          show-overflow-tooltip> </el-table-column>

        <el-table-column prop="receiverName" label="收货人" width="60" align="center" v-if="type == 'out'"
          show-overflow-tooltip> </el-table-column>
        <el-table-column prop="platformOrderNo" label="订单号" width="110" sortable align="center" show-overflow-tooltip v-if="type == 'out'"> </el-table-column>
        <el-table-column prop="stockNumber" label="数量" align="right" width="60"> </el-table-column>
        <el-table-column prop="stockOrigin" label="原数量" align="right" width="65"> </el-table-column>
        <el-table-column prop="labelPrice" label="标签价" align="right" sortable width="85"> </el-table-column>
        <!-- <el-table-column prop="weight" label="克重/规格" width="90" align="center" class-name="noprint">
          <template #default="scope">
            {{ scope.row.weight && scope.row.unitName ? (scope.row.weight || "0") + "g / " + (scope.row.unitName || "无") : ""}}
          </template>
        </el-table-column> -->
        <el-table-column prop="weight" label="克重" align="right" sortable width="80"> </el-table-column>
        <el-table-column prop="unitName" label="规格" align="right" sortable width="80" show-overflow-tooltip> </el-table-column>
        <el-table-column prop="purchasePrice" width="80" label="采购价"> </el-table-column>
        <el-table-column prop="productPrice" width="80" label="入库价"> </el-table-column>
        <el-table-column prop="sellPrice" width="70" label="售价"> </el-table-column>
        <el-table-column prop="fittingName" label="辅料" width="90" align="center" show-overflow-tooltip> </el-table-column>
        <el-table-column prop="outOrderNo" label="发货单号" width="120" sortable align="center" show-overflow-tooltip v-if="type == 'out'"> </el-table-column>
        <el-table-column prop="remark" label="备注" width="150" show-overflow-tooltip> </el-table-column>
        <el-table-column prop="stockReason" label="提示" width="150" show-overflow-tooltip> </el-table-column>
      </el-table>

      <el-row type="flex" class="inputfeed" justify="center">
        <el-col :span="5">
          操作： {{ bill.handoverName }}
        </el-col>
        <el-col :span="5">
          交接： {{ bill.reviewerName }}
        </el-col>
        <el-col :span="5">
          复核： {{ bill.operatorName }}
        </el-col>
        <el-col :span="5">
          财务： {{ bill.financerName }}
        </el-col>
      </el-row>
    </section>
  </el-card>
</template>
<script>
import printJS from "print-js";
import { fetchBillByCode } from "@/api/stock";
export default {
  name: "detail",
  components: {},
  data() {
    return {
      billCode: null,
      bill: {},
      bounds: [],
      title: null,

      type: "", //in OR out
      dict: {
        inBillTypes: [],
        outBillTypes: [],
      },

      props: {
        checkStrictly: true,
      },
      companys: [],
      companyName: '',
    };
  },
  methods: {
    loadData() {
      fetchBillByCode(this.billCode).then((res) => {
        let that = this;
        if (res.code == 200) {
          this.bill = res.data.bill;
          this.companyName = this.companys.filter(function (q) { return q.id == that.bill.companyID })[0].companyName;
          this.bounds = res.data.bounds;
          this.title = res.data.title;

          var sumNumber = 0; var sumWeight = 0;
          for (let i = 0; i < this.bounds.length; i++) {
            sumNumber += parseFloat(this.bounds[i].stockNumber);
            sumWeight += parseFloat(this.bounds[i].weight);
          }
          this.bounds.push({
            channel: '【合计】',
            stockNumber: sumNumber.toFixed(2),
            weight: sumWeight.toFixed(2)
          })
        }
      });
    },
    back() {
      this.$router.go(-1);
    },
    //打印
    onprint() {
      // let noprints = document.getElementsByClassName("caret-wrapper");
      // for (let i = 0; i < noprints.length; i++) {
      //   noprints[i].style.display = "none";
      // }

      // printJS({
      //   printable: "print-panel",
      //   type: "html",
      //   style:
      //     ".el-table td, .el-table th.is-leaf { border-bottom:1px solid #EBEEF5; }",
      // });


      //console.log(this.bill.companyId)
      let printData = [];

      let normalSum = 0; let fitSum = 0;
      for (let i = 0; i < this.bounds.length; i++) {
        let row = this.bounds[i];
        if ((row.detailName || '') == '') continue;
        if (row.detailName.indexOf('[辅料]') > -1)
          fitSum++;
        else
          normalSum++;

        printData.push({
          '序号': (i + 1),
          '渠道': this.spitBr(row.channel || '', 2),
          '库房': row.storeCode,
          '系统款号<br>品牌款号': row.detailCode + "<br>" + (row.brandModelCode || ''),
          '平台': row.platformName,
          '批次号': (row.brandSerialCode || ''),
          '名称': this.spitOmit(row.detailName || '', 15),
          '数量': row.stockNumber,
          '克重': row.weight,
          '规格': row.unitName,
          '辅料': (row.fittingName || ''),
          '备注': this.spitBr(row.remark || '', 10),
          '订单号': this.spitBr(row.platformOrderNo || '', 10),
          '收货人': (row.receiverName || ''),
        });
      }

      let title = this.companyName + "<span style='float:right'>" + this.bill.billDate + "</span>" + "<br>";
      if (this.bounds.length > 0 && this.bounds[0].stockNumber > 0) title += '[' + this.bill.billType + ']入库单';
      else if (this.bounds.length > 0 && this.bounds[0].stockNumber < 0) title += '[' + this.bill.billType + ']出库单';
      title += "(正常货品 " + normalSum + ' 件，辅料 ' + fitSum + " 件) " + "<span style='float:right'>" + this.bill.billCode + "</span>";

      title += "<br>";

      title += "<span style='font-size:0.4em;float:right; margin-bottom:10px;'>";
      title += "[操作： " + (this.bill.handoverName || this.bill.createUserName) + "]、";
      title += "[交接： " + (this.bill.reviewerName || "无") + "]、";
      title += "[复核： " + (this.bill.operatorName || "无") + "]、";
      title += "[财务： " + (this.bill.financerName || "无") + "] ";
      title += "</span>";

      printJS({
        header: '<h3 class="">' + title + '</h3>',
        printable: printData,
        properties: ['序号', '渠道', '库房', '系统款号<br>品牌款号', '平台', '批次号', '名称', '数量', '克重', '规格', '辅料', '备注', '订单号', '收货人'],
        type: 'json',
        gridHeaderStyle: 'font-size:0.5em;font-weight:bold;border: 1px solid #ccc;white-space:nowrap',
        gridStyle: 'padding:1px 2px;',
        style: 'table tr td{font-size:0.4em;border: 1px solid #ccc;text-align:center;white-space:nowrap }'
      })
    },
    spitBr(str, len) {
      let restr = '';
      len = len || 10;
      let i = 1;
      while (str.length > len) {
        restr += str.substr(0, len) + '<br>';
        str = str.substr(len, str.length);
        i++;
      }
      restr += str;
      return restr;
    },
    spitOmit(str, len) {
      return (str || '').length > (len || 10) ? str.substr(0, len || 10) + "…" : str;
    }
  },
  mounted() { },
  created() {
    this.$kaung.dictionary("stock", "inbound").then(res => this.dict.inBillTypes = res);
    this.$kaung.dictionary("stock", "outbound").then(res => this.dict.outBillTypes = res);

    this.$kaung.companys().then((res) => {
      this.companys = res;
    });

    this.billCode = this.$route.query.billCode;
    this.loadData();
  },
  watch: {
    bill: {
      handler: function (bill, old) {
        let inOptions = this.dict.inBillTypes.filter(function (item) {
          return item.name == bill.billType;
        });
        if (inOptions && inOptions.length > 0) {
          this.type = "in";
        }

        let outOptions = this.dict.outBillTypes.filter(function (item) {
          return item.name == bill.billType;
        });
        if (outOptions && outOptions.length > 0) {
          this.type = "out";
        }
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.title-box {
  border: 1px solid #dcdfe6;
  border-bottom: 0;
}
</style>